<template>
  <div v-if="pdfAPIReady === false || pdfReady === false">
    <div class="w-100 text-center">
      <h3 v-if="pdfAPIReady" />
      <b-row>
        <b-col md="3 text-center mt-2">
          <b-button
            v-if="!isCalc"
            class="mb-1"
            block
            variant="primary"
            @click="create()"
          >
            Vereinbarung erstellen
          </b-button>
          <b-button
            v-if="isCalc"
            variant="primary"
            disabled
            class="mr-1"
          >
            <b-spinner small />
            Erstellung läuft...
          </b-button>
        </b-col>

      </b-row>
      <div
        id="pdf-view"
        style="height: 50vh;"
      />
    </div>
  </div>
  <div v-else>
    <div
      v-if="pdfAPIReady === true && pdfReady === true"
      class="w-100 text-center"
    >
      <b-row>
        <b-col v-show="pdf !== null && refreshCnt > 0" cols="12" md="1">
          <b-form-group
            label="Aktualisieren"
            label-for="refresh"
          >
            <b-form-checkbox
              v-model="refresh"
              class="custom-control-primary"
              name="check-button"
              switch
            />
          </b-form-group>
        </b-col>

        <b-col v-show="pdf !== null && refresh && doss.MitUnterhalt" cols="12" md="1">
          <b-form-group
            label="Unterhalt"
            label-for="refreshKidz"
          >
            <b-form-checkbox
              v-model="refreshUnterhalt"
              class="custom-control-primary"
              name="check-button"
              switch
            />
          </b-form-group>
        </b-col>

        <b-col v-show="pdf !== null && refresh && doss.MitVA" cols="12" md="1">
          <b-form-group
            label="Vorsorge"
            label-for="refreshVA"
          >
            <b-form-checkbox
              v-model="refreshVA"
              class="custom-control-primary"
              name="check-button"
              switch
            />
          </b-form-group>
        </b-col>

        <b-col v-show="pdf !== null && refresh && doss.MitGA" cols="12" md="1">
          <b-form-group
            label="Güterrecht"
            label-for="refreshGA"
          >
            <b-form-checkbox
              v-model="refreshGA"
              class="custom-control-primary"
              name="check-button"
              switch
            />
          </b-form-group>
        </b-col>

        <!-- <b-col cols="12" :md="(pdf === null ? 12 : !refresh ? 9 : 6)"> -->

        <b-col cols="12" md="3" :offset-md="pdf === null ? 9 : refreshCnt === 0 ? 7 : !refresh ? 6 : 6 - refreshCnt">
          <b-button
            v-if="!isCalc"
            block
            variant="primary"
            @click="create()"
          >
            {{ "Vereinbarung " + (pdf !== null && refresh ? "aktualisieren" : "erstellen") }}
          </b-button>
          <b-button
            v-if="isCalc"
            variant="primary"
            disabled
            class="mr-1"
          >
            <b-spinner small />
            Erstellung läuft...
          </b-button>
        </b-col>

        <b-col v-show="pdf !== null" cols="12" md="2 text-right">
          <b-button
            variant="primary"
            @click="show()"
          >
            Bearbeiten
          </b-button>
        </b-col>

      </b-row>
      <div
        id="pdf-view"
        style="height: 75vh;"
      />
    </div>

    <div v-show="false">
      <word-editor
        ref="we"
        dateiName="Vereinbarung"
        :doc-in="docIn"
        :modal-height="modalHeight"
        @getDocBlob="getDocBlob"
      />
    </div>

  </div>

</template>

<script>
import {
  BButton, BSpinner, BRow, BCol, BFormGroup, BFormCheckbox,
} from 'bootstrap-vue'
import ver from '@/service/vereinbarung'
import dos from '@/service/dossier'
import wordEditor from '../../compo/WordEditor.vue'

export default {
  components: {
    BButton,
    BSpinner,
    BRow,
    BCol,
    BFormGroup,
    BFormCheckbox,
    wordEditor,
  },
  data() {
    const ADOBE_KEY = (window.location.href.toString().includes('localhost') ? 'b128238126aa454b8bb908b2a06cd02f' : 'a86fda26efa441559b28d422cbadb90b')
    const viewerConfig = {
      embedMode: 'LIGHT_BOX',
    }
    return {
      doss: null,
      data: null,
      refreshCnt: 3,
      refresh: false,
      refreshUnterhalt: false,
      refreshGA: false,
      refreshVA: false,
      file: null,
      docIn: null,
      blobOut: null,
      pdf: null,
      pdfReady: false,
      ADOBE_KEY,
      viewerConfig,
      isCalc: false,
      pdfAPIReady: this.$root.pdfAPIReady,
      modalHeight: window.innerHeight - 300,
    }
  },
  async created() {
    await this.loadShowPdf()
    const r = await dos.getDossier(this.$root.currDosID)
    if (r.data.length > 0) {
      this.doss = r.data[0]
      this.refreshUnterhalt = this.doss.MitUnterhalt
      this.refreshGA = this.doss.MitGA
      this.refreshVA = this.doss.MitVA
      this.refreshCnt = this.refreshUnterhalt ? 1 : 0
      this.refreshCnt += this.refreshVA ? 1 : 0
      this.refreshCnt += this.refreshGA ? 1 : 0
    }
  },
  methods: {
    async create() {
      const msg = `Soll die bestehende Vereinbarung ${this.refresh ? 'aktualisiert' : 'überschrieben'} werden?`
      if (this.pdf === null || await this.$root.msgBoxConfirm(msg)) {
        this.isCalc = true
        if (this.refresh) await ver.updateVereinbarung(this.$root.currDosID, this.refreshUnterhalt, this.refreshVA, this.refreshGA) // eslint-disable-line
        else await ver.createVereinbarung(this.$root.currDosID) // eslint-disable-line
        await this.loadShowPdf()
        this.isCalc = false

        this.$root.showToast(this.$g.browserLocale === 'fr' ? 'Convention' : 'Vereinbarung', this.$g.browserLocale === 'fr' ? 'La convention a été crée avec succès!' : 'Die Vereinbarung wurde erfolgreich ' + (this.refresh ? 'aktualisiert!' : 'erstellt!'))
      }
    },
    async show() {
      // const r = await ver.getVereinbarungSFDT(this.$root.currDosID)
      // if (r != null) this.docIn = r.data
      this.$refs.we.show()
    },
    async getDocBlob(blob, doc) { // Speichern aufgerufen aus word editor Komponente...
      this.blobOut = blob
      this.docIn = doc
      await ver.postVereinbarungSFDT(this.blobOut, this.$root.currDosID) // eslint-disable-line
      this.$root.showToast('Vereinbarung gespeichert', 'Vereinbarung wurde erfolgreich gespeichert!')
      await this.loadShowPdf()
    },
    async loadShowPdf() {
      const r = await ver.getVereinbarungDatei(this.$root.currDosID, false) // Read pdf if already generated...
      if (r !== null && r.data !== null && r.data.size !== 0) {
        this.data = r.data
        this.pdf = new Blob([r.data], { type: 'application/pdf' })
        await this.previewPDF()
      }
      const di = await ver.getVereinbarungSFDT(this.$root.currDosID)
      if (di != null) this.docIn = di.data
    },
    async previewPDF() {
      if (this.pdfAPIReady) {
        this.pdfReady = false
        const av = new window.AdobeDC.View({ clientId: this.ADOBE_KEY, divId: 'pdf-view', locale: (this.$g.user.Sprache === 'fr' ? 'fr-FR' : 'de-DE') })
        av.previewFile({ content: { promise: Promise.resolve(this.pdf.arrayBuffer()) }, metaData: { fileName: 'Vereinbarung' }, configuration: this.viewerConfig }) // eslint-disable-line
        this.pdfReady = true
      }
    },
  },
}
</script>
